<style>
	/* body {
		background-color: #4a97f7;
	} */
	/* .login-box-container {
		-webkit-box-shadow: 0px 0px 11px -2px #000000; 
		box-shadow: 0px 0px 11px -2px #000000;
	} */
	.img-auth {
		height: 450px;
	}
	.authent-logo img {
		margin-top: 10px;
		height: 50px;
	}
	.authent-text {
		padding-top: 15px;
		font-weight: bold;
		font-size: 18px;
		letter-spacing: 0.5px;
	}
	.login-box-container {

	}
</style>
<template>
	<div>
		<div class="container">
		    <div class="row">
		    	<div class="col-lg-8 hidden-xs text-center">
		    		<img class="img-auth" :src="require('@/assets/images/pattern.png')" alt="">
		    	</div>
		        <div class="col-lg-4">
		            <div class="panel login-box-container">
		                <div class="panel-body">
		                    <div class="authent-logo text-center" v-if="settings.site_icon">
		                       	<img :src="settings.site_icon" alt="" />
		                    </div>


		                    <div class="authent-text text-center">
		                    	<p>{{ settings.site_title }}</p>
		                    </div>

		                    <div class="alert text-center" :class="[messageFormStatus == 1 ? 'b-success' : 'b-danger']" v-if="messageForm">
		                    	{{ messageForm }}
		                    </div>

		                    <form v-if="this.$route.name == 'SetupSite'" @submit.prevent="setup">
		                    	<div class="form-group">
		                    		<label for="floatingPassword">API Token :</label>
		                    		<input type="password" class="form-control" id="floatingPassword" v-model="formSetup.api_token" placeholder="Nhập mã API Token" required="">
		                    	</div>
		                        <button type="submit" class="btn btn-info b-info btn-block">KHỞI TẠO</button>
		                        <br>
		                        <div class="alert b-success">Liên hệ Admin hoặc vào phần Tạo Website CTV để lấy API Token</div>
		                    </form>

		                    <form v-if="this.$route.name == 'Login'" @submit.prevent="login">
		                        <div class="mb-3">
		                            <div class="form-group">
		                            	<label for="floatingInput">Tài khoản</label>
		                                <input type="text" class="form-control" id="floatingInput" v-model="formAuth.username" placeholder="Tài khoản" required="">
		                            </div>
		                        </div>
		                        <div class="mb-3">
		                            <div class="form-group">
		                            	<label for="floatingPassword">Mật khẩu</label>
		                                <input type="password" class="form-control" id="floatingPassword" v-model="formAuth.password" placeholder="Mật khẩu" required="">
		                            </div>
		                        </div>
		                        <div class="mb-3 form-check">
		                            <label class="form-check-label" for="exampleCheck1">
		                            	<input type="checkbox" class="form-check-input" id="exampleCheck1" checked="" />
			                            Ghi nhớ đăng nhập?
			                        </label>
		                        </div>
		                        <button type="submit" class="btn btn-success b-success btn-block">ĐĂNG NHẬP</button>
		                        <div class="text-center font-weight-bold p-2">hoặc</div>
		                        <router-link class="btn btn-info b-info btn-block" to="/register">ĐĂNG KÝ TÀI KHOẢN</router-link>
		                    </form>

		                    <form v-if="this.$route.name == 'Register'" @submit.prevent="register">
		                        <div class="mb-3">
		                            <div class="form-group">
		                            	<label for="floatingInput">Tài khoản</label>
		                                <input type="text" class="form-control" id="floatingInput" v-model="formAuth.username" placeholder="Tài khoản" required="">
		                            </div>
		                        </div>
		                        <div class="mb-3">
		                            <div class="form-group">
		                            	<label for="floatingPassword">Mật khẩu</label>
		                                <input type="password" class="form-control" id="" v-model="formAuth.password" placeholder="Mật khẩu" required="">
		                            </div>
		                        </div>
		                        <div class="mb-3">
		                            <div class="form-group">
		                            	<label for="floatingPassword">Nhập lại mật khẩu</label>
		                                <input type="password" class="form-control" id="" v-model="formAuth.confirm_password" placeholder="Nhập lại mật khẩu" required="">
		                            </div>
		                        </div>
		                        <button type="submit" class="btn btn-info b-info btn-block">ĐĂNG KÝ</button>
		                        <div class="text-center font-weight-bold p-2">hoặc</div>
		                        <router-link class="btn btn-success b-success btn-block" to="/login">ĐĂNG NHẬP TÀI KHOẢN</router-link>
		                    </form>
		                    
		                </div>
		            </div>
		        </div>
		    </div>
		</div>
	</div>
</template>

<style scoped="">
	.container {
		padding-top: 70px;
	}
</style>

<script>
	export default {
		props: ['settings'],
		data () {
			return {
				formAuth: {},
				formSetup: {},
				messageForm: '',
				messageFormStatus: 0,
			}
		},
		created () {
			if (this.$route.name == 'SetupSite' && this.settings) {
				this.$router.push('/login');
			}
			$('body').addClass('bg-main');
		},
		methods: {
			setup () {
				this.$http.post('setup', this.formSetup).then(res => {
					if (res.body.status > 0) {
						this.$router.push('/login');
					}
					this.action_result(res.body);
				});
			},
			login () {
				this.messageForm = '';
				this.formAuth.action = 'login';
				this.$http.post('auth', this.formAuth).then(res => {
					res = res.body;
					if (res.status > 0) {
			            this.$store.state.updateSidebar = 0;
						this.$store.commit('setAuthToken', res.user.token);
						this.$router.push('/');
					}
					this.action_result(res);
				});
			},
			register () {
				this.messageForm = '';
				this.formAuth.action = 'register';
				this.$http.post('auth', this.formAuth).then(res => {
					res = res.body;
					if (res.status > 0) {
						this.$router.push('/login');
					}
					this.action_result(res);
				});
			},
			action_result (res) {
				if (res.status > 0) {
					this.formAuth = {};
				}
				this.messageFormStatus = res.status;
				this.messageForm = res.message;
				this.$swal('Thông báo', res.message, (res.status == 1 ? 'success' : 'error'));
			}
		}
	}
</script>